import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import Perk from "./Perk"

const PerksModule = () => {
    return (
        <PerksModuleStyles>
            <StaticImage
                className="perks__image--bg"
                src="../../images/abstract-building.jpg"
                alt="Perks Module"
                layout="constrained"
                placeholder="tracedSVG"
            />
            <div className="perks__image--overlay"></div>
            <div className="container">
                <Perk
                    title="Built For Speed"
                    content="By using GatsbyJS, the Barcadia site is super-fast out of the box"
                >
                    <StaticImage
                        src="../../images/logos/gatsby-logo.svg"
                        alt="Perk Image"
                        layout="constrained"
                        placeholder="tracedSVG"
                    />
                </Perk>
                <Perk
                    title="Built For Content"
                    content="Contentful helps you edit your application with ease as your business expands"
                >
                    <StaticImage
                        src="../../images/logos/contentful-logo.svg"
                        alt="Perk Image"
                        layout="constrained"
                        placeholder="tracedSVG"
                    />
                </Perk>
                <Perk
                    title="Built For Security"
                    content="A static site reduces your chance of a security exposure through injection"
                >
                    <StaticImage
                        src="../../images/logos/netlify-logo.svg"
                        alt="Perk Image"
                        layout="constrained"
                        placeholder="tracedSVG"
                    />
                </Perk>
            </div>
        </PerksModuleStyles>
    )
}

export default PerksModule
